import { FiHome, FiBook, FiShoppingBag } from "react-icons/fi";

const links = [
    {
        name: "Home",
        path: "/",
        icon: <FiHome />
    },
    {
        name: "Product",
        path: "/product",
        icon: <FiShoppingBag />
    },
    {
        name: "Contact",
        path: "/contact",
        icon: <FiBook />
    },
    // {
    //     name: "Settings",
    //     path: "/settings",
    //     icon: <FiSettings />
    // },
]

export default links;