import HomeLogo from "../../images/logo.png";
import "../../styles/partials/footer.scss";

import { FiFacebook, FiTwitter, FiLinkedin } from "react-icons/fi";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container grid2">
          <div className="box">
            <img src={HomeLogo} className="footer_logo" alt="footer_logo" />
            <p>
              1. Chief Bode Moses street, Ibafo, <br />
              Lagos-Ibadan express- way,
              <br />
              Ogun State, Nigeria
            </p>
          </div>

          <div className="box">
            <a href="/product" className="h2">
              Product
            </a>
          </div>
          <div className="box">
            <a href="/contact" className="h2">
              Contact
            </a>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/trinityGoldenCrownLtd"
                target="_blank"
                rel="noreferrer"
              >
                <FiFacebook
                  alt="alternative"
                  className="footer-img"
                  style={{ width: 30 + "px", height: 30 + "px", color: "white", paddingRight: 7 }}
                />
              </a>
              <a href=" https://twitter.com/" target="_blank" rel="noreferrer">
                <FiTwitter
                  alt="alternative"
                  className="footer-img"
                  style={{ width: 30 + "px", height: 30 + "px", color: "white", paddingRight: 7 }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/trinity-golden-crown-ltd/"
                target="_blank"
                rel="noreferrer"
              >
                <FiLinkedin
                  alt="alternative"
                  className="footer-img"
                  style={{ width: 30 + "px", height: 30 + "px", color: "white", paddingRight: 7 }}
                />
              </a>
            </div>
          </div>
        </div>
        <hr className="container" />
        <div>
          <p className="footer-center">
            &#169; 2023 TGC Limited. All Rights Reserved
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
