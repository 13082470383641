import React from "react";
import { useNavigate } from "react-router-dom";
import products from "./products.js";

import "../../styles/partials/productList.scss";

const ProductList = () => {
    const navigate = useNavigate();

    const GoToNextPage = (id) => {
        navigate(`/product/${id}`);
    }
  return (
    <>
      <div className="container product-listing-page">
        {products.map((product) => (
          <div className="card" key={product.id} onClick={() => GoToNextPage(product.id)}>
            <img src={product.image} alt={product.name} />
            <h3>{product.name}</h3>
            {/* <p>{product.description}</p>
            <p>{product.price}</p> */}
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductList;
