import React from "react";
import { useParams } from "react-router-dom";
import products from "../product/products.js";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import "../../styles/partials/productDetail.scss";

const ProductDetail = () => {

  const { id } = useParams();
  const product = products?.find((p) => p.id === parseInt(id));

  if (!product) {
    return <div>Product not found</div>;
  }
  return (
    <>
      <Navbar />
      <div className="container product-details-page">
        <img src={product.image} alt={product.name} />
        <h1>{product.name}</h1>
        <p>{product.description}</p>
        <p>${product.price}</p>
      </div>
      <Footer />
    </>
  );
};

export default ProductDetail;
