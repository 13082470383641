import React from "react";
import "../../styles/partials/_sidebar.scss";

const Sidebar = ({ links, close }) => {
  return (
    <div className="sidebar" onClick={close}>
      {links.map((link) => (
        <a className="sidebar_link" href="#!" key={link.name}>
          {link.icon}
          {link.name}
        </a>
      ))}
    </div>
  );
};

export default Sidebar;
