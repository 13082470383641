import { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import HomeLogo from "../../images/logo.png";
import links from "./navLinks";

import "../../styles/partials/_navbar.scss";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closeSidebar = () => {
setIsOpen(false)
  }

  return (
    <>
      <div className="navbar container">
        <a href="/" className="navlogo__links">
          <img src={HomeLogo} alt="home_logo" />
          <p>
            Trinity <span>Golden</span> Crown Limited
          </p>
        </a>
        <div className="nav__links">
          {links.map(link => (
            <a href={link.path} key={link.name}>{link.name}</a>
          ))}
        </div>

        <div
          onClick={() => setIsOpen(true)}
          className={isOpen ? "sidebar_btn active" : "sidebar_btn"}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
      {isOpen && <Sidebar close={closeSidebar} links={links} /> }
      
    </>
  );
};

export default Navbar;
