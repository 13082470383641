import TigerNut from "../../images/tiger-nuts-open.jpeg";
import TigerNutSack from "../../images/tiger-nuts-in-sack.jpeg";
import Garri from "../../images/white-garri.jpeg";
import Gallstones from "../../images/gallstones.png";
import Cashew from "../../images/cashew.png";
import InCashew from "../../images/cashew kernels.jpg";

const products = [
    {
        id: 1,
        name: 'Cashew',
        image: Cashew,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        price: 9.99,
    },
    {
        id: 2,
        name: 'Cashew Inside',
        image: InCashew,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        price: 19.99,
    },
    {
        id: 3,
        name: 'Gallstones',
        image: Gallstones,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        price: 29.99,
    },
    {
        id: 4,
        name: 'Garri',
        image: Garri,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        price: 29.99,
    },
    {
        id: 5,
        name: 'Tiger Nut',
        image: TigerNut,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        price: 29.99,
    },
    {
        id: 6,
        name: 'Sacked Tiger Nut',
        image: TigerNutSack,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        price: 29.99,
    },
]

export default products;